import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const StyledSection = styled.section`
    width: 100%;
    background-color: ${props => props.bgColor ? props.bgColor : "transparent"};
    padding-top: ${props => props.dense ? props.theme.spacing[6] : props.theme.spacing[8]};
    padding-bottom: ${props => props.dense ? props.theme.spacing[6] : props.theme.spacing[8]};
    ${props => props.theme.device.tablet} {
    padding-top: ${props => props.dense ? props.theme.spacing[7] : props.theme.spacing[10]};
    padding-bottom: ${props => props.dense ? props.theme.spacing[7] : props.theme.spacing[10]};
    }
    ${props => props.theme.device.laptop} {
    padding-top: ${props => props.dense ? props.theme.spacing[9] : props.theme.spacing[11]};
    padding-bottom: ${props => props.dense ? props.theme.spacing[9] : props.theme.spacing[11]};
    }
`;

const MotionSection = motion(StyledSection, {
    forwardMotionProps: true
})
const variants = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0 }
}

const Section = ({ children, bgColor, dense, id }) => {
    const [ref, inView] = useInView();
    const controls = useAnimation();

    useEffect(() => {
        // fade in section when in view
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <MotionSection
            bgColor={bgColor}
            dense={dense}
            ref={ref}
            animate={controls}
            variants={variants}
            initial="hidden"
            id={id}
        >
            {children}
        </MotionSection>
    )
}

export default Section