import React, { useContext } from "react";
import styled, { css } from "styled-components";

import AppStoreButton from "./AppStoreButton.js";
import { ModalContext } from "./modal-context";
import { OutboundLink } from "./Href.js";
import { motion } from "framer-motion";

// if there's no href passed, we assume this is a download button intended to open the modal
const Button = ({ href, small }) => {
  const [showModal, setShowModal] = useContext(ModalContext);
  const open = () => setShowModal(true);
  const buttonProps = {
    whileHover: { scale: 1.05 },
    href: href,
    onClick: href ? undefined : open,
  };
  const buttonContent = <AppStoreButton {...{ small }} />;
  return (
    <>
      {href ? (
        <HrefBase {...buttonProps}>{buttonContent}</HrefBase>
      ) : (
        <ButtonBase {...buttonProps}>{buttonContent}</ButtonBase>
      )}
    </>
  );
};

const HrefBase = (props) => {
  return <MotionLink {...props}>{props.children}</MotionLink>;
};

const ButtonBase = (props) => {
  return <MotionButton {...props}>{props.children}</MotionButton>;
};

const ButtonStyle = css`
  border: none;
  cursor: pointer;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  scale: 1;

  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2pt ${(props) => props.theme.color.bg},
      0 0 0 5pt ${(props) => props.theme.color.textSecondary};
  }
`;

const MotionButton = styled(motion.button)`
  ${ButtonStyle}
`;

const LinkElement = styled(OutboundLink)`
  ${ButtonStyle}
`;
const MotionLink = motion(LinkElement);

export default Button;
